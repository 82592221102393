.faq-item {
    border-radius: 8px;
    padding: 15px;
    cursor: pointer;
}

.faq-item:hover {
    background: #e9e9e9;
}

.faq-question {
    width: 80%;
    font-size: 18px;
    font-weight: bold;
}

.faq-answer {
    margin-top: 20px;
    font-size: 18px;
    color: #555;
    transition: max-height 0.3s ease-in-out;
}

.faq-item.open {
    background: #e0e0e0;
}
