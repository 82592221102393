.titleHeader {
    margin-top: 200px;
    font-weight: normal;
    font-family: 'Exo', sans-serif;
}

.photo--small {
    width: 100px;
    height: 100px;
}

.photo-container {
    display: flex;
    justify-content: space-around
}

.item--container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.margin-text {
    margin-left: 15px;
}

@media (max-width: 830px) {
    .photo-container {
        display: inline;
    }

    .item--container {
        margin: 10px 30px;
    }

    .photo {
        width: 90%;
    }
}