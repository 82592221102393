.faq-container {
    width: 100%;
    max-width: 90%;
    margin: 40px auto;
    padding: 20px;
    background: white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.faq-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
}

.faq-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

@media (max-width: 768px) {
    .faq-container {
        padding: 15px;
    }

    .faq-title {
        font-size: 24px;
    }
}
