/* Kontener na opinie */
.reviews-container {
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    text-align: center;
}

/* Grid do układania kafelków */
.reviews-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 100%;
}

/* Pojedynczy kafelek opinii */
.review-card {
    min-width: 25%;
    max-width: 200px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 20px 20px;
    text-align: left;
    transition: transform 0.2s ease-in-out;
}

.review-card:hover {
    transform: scale(1.03);
}

/* Nagłówek opinii - zdjęcie + autor */
.review-header {
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

@media (max-width: 1050px) {
    .review-card {
        min-width: 80%;
    }
}
